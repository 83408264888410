exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-lanyard-js": () => import("./../../../src/pages/AboutLanyard.js" /* webpackChunkName: "component---src-pages-about-lanyard-js" */),
  "component---src-pages-agenda-page-js": () => import("./../../../src/pages/AgendaPage.js" /* webpackChunkName: "component---src-pages-agenda-page-js" */),
  "component---src-pages-dash-board-js": () => import("./../../../src/pages/DashBoard.js" /* webpackChunkName: "component---src-pages-dash-board-js" */),
  "component---src-pages-home-page-js": () => import("./../../../src/pages/HomePage.js" /* webpackChunkName: "component---src-pages-home-page-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-login-js": () => import("./../../../src/pages/Login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-speakers-js": () => import("./../../../src/pages/Speakers.js" /* webpackChunkName: "component---src-pages-speakers-js" */),
  "component---src-pages-sponsers-js": () => import("./../../../src/pages/Sponsers.js" /* webpackChunkName: "component---src-pages-sponsers-js" */),
  "component---src-pages-thankyou-js": () => import("./../../../src/pages/Thankyou.js" /* webpackChunkName: "component---src-pages-thankyou-js" */),
  "component---src-pages-volunteers-js": () => import("./../../../src/pages/Volunteers.js" /* webpackChunkName: "component---src-pages-volunteers-js" */)
}

